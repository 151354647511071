import React, { Component } from 'react'

export default class Contact extends Component {
  render() {
    return (
      <div>
          <header className="contact" id="contact">
         <h2> Contact Me </h2>
        </header>
        <div>
        <div class="menu">
          <ul>
            <li><a href="mailto:kaylapeters916@gmail.com"target="_blank"rel="noopener noreferrer"><i className="icon-mail"></i> kaylapeters916@gmail.com </a></li>
            <li><a href="https://www.linkedin.com/in/kayla-peters-software-dev/" target="_blank"rel="noopener noreferrer"><i className="icon-linkedin2"></i> Linked In </a></li>
            <li><a href="https://www.instagram.com/ooh_kale_noo/" target="_blank"rel="noopener noreferrer"><i className="icon-instagram"> </i> Instagram </a></li>
            <li><a href="https://www.facebook.com/profile.php?id=100008462771056" target="_blank" rel="noopener noreferrer"><i className="icon-facebook2" /> Facebook </a></li>
            <li><a href="https://github.com/Kay-pet" target="_blank" rel="noopener noreferrer"><i className="icon-github"></i> Github </a></li>
          </ul>
        </div>
        </div>
      </div>
    )
  }
}
