import React, { Component } from 'react'

export default class Experience extends Component {
  render() {
    return (
      <div>
        
        <header className="experience" id="experience">
         <h2> Experience </h2>
        </header>
        <div className="experience-body-parent">
        <div id="education-skills">
        <h3>Education</h3>
          <p id="schooling"> University of California, Davis — B.S. Computer Science 2020 Minor in Mathematics </p>
          <h3>Skills</h3>
          <li><h4>Angular / React </h4></li>
          <li><h4>JavaScript / TypeScript</h4></li>
          <li><h4>HTML / CSS </h4></li>
          <li><h4>Ruby on Rails</h4></li>
          <li><h4>C++</h4></li>
          <li><h4>E2E Testing - Cypress</h4></li>
          <h3>Projects</h3>
          <p>Operating Systems - Simple Shell — Uc Davis
In C++, built a shell to execute applications, set up pipes between executed applications, redirect standard input/output from/to files for the executed applications, and perform simple commands internally (cd, ls, pwd, ff, and exit).</p>
        </div>
        <div id="job-experience">
        <div id="job-history">
            <li><span> Software Engineer - MyCase </span></li>
            <li id="location">San Diego, CA [ May 2021 - Present]</li>
            <li>- Full Stack web development using TypeScript, Ruby on Rails, React and AWS</li>
            <li>- Integrated third party software into existing framework</li>
            <li>- Develop, test, document and release full-stack, end-to-end features for MyCase’s web application </li>
            </div>
          <div id="job-history">
            <li><span> Software Developer - OSIsoft </span></li>
            <li id="location">San Leandro, CA [August 2020 - May 2021]</li>
            <li>- Develop APIs, Data storage, and UI to enable a more comprehensive testing</li>
            <li>- Implemented functionality and refactored routes in code base </li>
            <li>- Analyze data, provide insights, and communicate findings to peers </li>
            <li>- Utilize Agile, Angular and TypeScript/JavaScript as well as React </li>
            </div>
            <div id="job-history">
            <li><span> Software Engineering Intern - OSIsoft </span></li>
            <li id="location">San Leandro, CA [June 2019 - September 2019]</li>
            <li>- Tested production ready Application with Cypress End to End/Integration Testing, 
              Unit Testing in JavaScript, and Telemetry to improve user interaction</li>
            <li>- Used Agile with my team to complete sprints and set future goals </li>
            <li>- Debugged and implemented JavaScript code for the company's User Interface </li>
            </div>
            <div id="job-history">
            <li><span> UC Davis CRUSIT </span></li>
            <li id="location">Davis, CA [August 2018 - April 2020]</li>
            <li>- Provide front line support for a wide range of devices, including Mac/Windows/Linux</li>
            <li>- Reimage devices, and work with my campus’ VLAN Network </li>
            <li>- Communicate and efficiently troubleshoot technical problems </li>
            </div>
            <div id="job-history">
            <li><span> Software Engineering Intern - AEInnova </span></li>
            <li id="location">Barcelona, Spain [June 2018 - August 2018]</li>
            <li>- Converted and optimized a legacy efficiency algorithm for their startup’s hardware Product from MATLAB to Python3</li>
            <li>- Created user interface to implement the algorithm using Django, Python3, and Bootstrap </li>
            <li>- Collaborated and delivered production ready code in a fast-paced environment </li>
            </div>
        </div>
        </div>
        </div>
    )
  }
}
