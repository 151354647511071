import React, { Component } from 'react';
export default class About extends Component {
  render() {
    return (
        <div>
        <header className="about" id="about">
         <h2> About Me </h2>
        </header>
        <div className="about-me-body">
        <p>Favorite Book <i className="icon-book" aria-hidden="true"></i> -   Euphoria by Lily King</p>
          <p>Go to Beer  <i className="icon-beer" aria-hidden="true"></i> -   anything Sour</p>
          <p>Weekends <i className="icon-tree" aria-hidden="true"></i> -   Camping / Baking / Snowboarding</p>
          </div>
          <div className="photos">
          </div>
        </div>
      );
  }
}

